<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">RASCAL Software </font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><em>
              </em><div align="left">
                <dl>
                  <p><a href="https://cave.cs.columbia.edu/old/software/rascal/download/RASCAL.zip">Download <em>RASCAL Software </em>(zip file, 2.7MB)</a></p>
                  <p>Download Test Images<br>
                    <br>
                  </p>
                  <table width="70%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr valign="baseline">
                      <td><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7708.18-23.zip"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.18-23.jpg" border="0" width="55" height="88"><br>
(zip file, 726KB)</a></td>
                      <td><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7708.1-5.zip"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.1-5.jpg" border="0" width="88" height="55"><br>
(zip file, 863KB)</a></td>
                      <td><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7708.6-11.zip"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.6-11.jpg" border="0" width="88" height="55"><br>
(zip file, 719KB)</a></td>
                    </tr>
                    <tr valign="baseline">
                      <td><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7708.24-29.zip"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.24-29.jpg" border="0" width="55" height="88"><br>
(zip file, 769KB)</a></td>
                      <td><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7710.1-6.zip"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7710.1-6.jpg" border="0" width="55" height="85"><br>
(zip file,<br>
675KB</a></td>
                      <td><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7710.13-18.zip"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7710.13-18.jpg" border="0" width="88" height="55"><br>
(zip file, 945KB)</a></td>
                    </tr>
                  </tbody></table>
                  <blockquote><p><a href="https://cave.cs.columbia.edu/old/software/rascal/download/7708.18-23.zip"><br>
                    </a></p>
                  </blockquote>
                </dl>
              </div>
            </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p>last updated :
                    <!--webbot bot="Timestamp" startspan
s-type="EDITED" s-format="%m/%d/%y" -->
                    07/29/99
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </p>
                 <p> [<router-link to="/repository/RASCAL">Goto RASCAL</router-link>]<span class="style1">
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </span></p>
                </div>
            </div></td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>